import React, {useState} from 'react';
import styled from 'styled-components';
import {Helmet, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {colors, media, styles} from 'global.config';
import Stars from 'components/Stars';
import TopSectionImage from 'images/spam-illustration.svg';
import Support from 'images/looking-for-support.svg';
import Other from 'images/otheruseful.svg';
import {AnchorLink} from 'components/AnchorLink';
import Button from 'components/Button';
import Loader from 'components/Loader';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 150px;
  position: relative;
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 650px;
  margin: 15px auto 50px;
  font-weight: 300;
`;

const SpamDescriptionContainer = styled.div`
  display: flex;
  margin: 0 auto 150px;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: space-around;
`;

const SpamDescriptionContent = styled.div`
  margin: 0 20px 20px;
  max-width: 45%;
  @media ${media.mobile} {
    width: 100%;
    max-width: 100%;
    margin: 0 0 40px 0;
  }
  img {
    margin-bottom: 0;
  }
  h2 {
    font-size: 30px;
    color: ${colors.text};
    font-weight: 500;
    margin: 30px 0 20px;
    @media ${media.mobile} {
      font-size: 24px;
      margin: 15px 0 10px;
    }
  }
  ul {
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 16px;
      padding: 6px 0 6px 18px;
      position: relative;
      margin-bottom: 0;
      line-height: 1.15;
      a {
        color: ${colors.action};
        font-weight: 600;
      }
      &:before {
        content: '\u2022';
        color: ${colors.action};
        font-size: 28px;
        line-height: 17px;
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const SpamFormContainer = styled.div`
  position: relative;
  min-height: 450px;
  margin-top: -150px;
`;
const SpamForm = styled.div<{isSubmitted: boolean}>`
  color: ${colors.text};
  max-width: 400px;
  padding: 30px 40px;
  border-radius: 7px;
  position: relative;
  margin: auto;
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.06);
  min-height: 350px;
  transition: all 0.3s;
  ${props =>
  props.isSubmitted &&
  `
        max-width: 400px;
        min-height: 100px;
        top: 80px;
        @media ${media.mobile} {
            top: 100px;
            max-width: 95%;
        }
    `}
  @media ${media.mobile} {
    max-width: 95%;
    padding: 15px 30px;
  }
  form {
    display: block;
    width: 100%;
  }
  p {
    margin-bottom: 0;
  }
`;

const SubmitSuccessMessage = styled.p`
  text-align: center;
  font-size: 16px;
  color: #000000;
`;

const SpamFormItem = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #000000;
  }
  input,
  textarea {
    width: 100%;
    margin-bottom: 15px;
    height: 37px;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px;
    color: #000000;
    font-size: 14px;
    background-color: #fbfbfb;
    border: solid 1px #e1e1e1;
    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &::-webkit-input-placeholder {
      color: #c7c7c7;
    }
  }
  textarea {
    height: 120px;
    resize: none;
  }
  button {
    text-align: center;
  }
`;

const Page: React.FC = ({}) => {
  const {t} = useI18next();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Helmet title={t('Spam')}>
        <meta name="description" content={t('Getting Spam from a MoonMail user? Report it now!')}/>
      </Helmet>
      <div>
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Zapier Email marketing software')}
                title={t('Zapier Email marketing software')}
                width={296}
                height={300}
              />
              <h1>Receiving Spam From MoonMail?</h1>
              <IndexSectionSummary>
                <Trans>
                  For questions about our Managed Email Service, use the form below to contact our
                  team and we'll follow up with you shortly.
                </Trans>
              </IndexSectionSummary>
            </IndexSectionContainer>
          </IndexSection>
        </Stars>
        <SpamFormContainer>
          <SpamForm isSubmitted={isSubmitted}>
            {isLoading && (
              <SubmitSuccessMessage>
                <Loader/>
              </SubmitSuccessMessage>
            )}
            {!isLoading && isSubmitted && (
              <SubmitSuccessMessage>
                <Trans>We've received your submission. Thank You !</Trans>
              </SubmitSuccessMessage>
            )}
            {!isLoading && !isSubmitted && (
              <form
                onSubmit={() => {
                  setIsLoading(true);
                  setTimeout(() => {
                    setIsSubmitted(true);
                    setIsLoading(false);
                  }, Math.floor(Math.random() * Math.floor(600)) + 400);
                }}>
                <SpamFormItem>
                  <label>{t('Your Email Address')}</label>
                  <input type="email" name="email" required/>
                </SpamFormItem>
                <SpamFormItem>
                  <label>{t('Email headers')}</label>
                  <textarea
                    name="headers"
                    placeholder={t(
                      'Please, copy and paste the full message headers from the unsolicited message/email.'
                    )}/>
                </SpamFormItem>
                <SpamFormItem>
                  <Button as="button">{t('Submit')}</Button>
                </SpamFormItem>
              </form>
            )}
          </SpamForm>
        </SpamFormContainer>
        <SpamDescriptionContainer>
          <SpamDescriptionContent>
            <img src={Support} width="85" height="89"/>
            <h2>
              <Trans>Looking for Support?</Trans>
            </h2>
            <ul>
              <li>
                <Trans>
                  Search our{' '}
                  <AnchorLink href="https://support.moonmail.io/en/">Help Center</AnchorLink> for an
                  answer to your question
                </Trans>
              </li>
              <li>
                <Trans>
                  Log in to{' '}
                  <AnchorLink href="https://dashboard.moonmail.io/">
                    submit a support ticket
                  </AnchorLink>
                </Trans>
              </li>
            </ul>
          </SpamDescriptionContent>
          <SpamDescriptionContent>
            <img src={Other} width="85" height="89"/>
            <h2>
              <Trans>Other Useful Links</Trans>
            </h2>
            <ul>
              <li>
                <AnchorLink external target="_blank">
                  {t('Terms of Service')}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink external target="_blank">
                  {t('Anti-Spam Policy')}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink external target="_blank">
                  {t('MoonMail on GitHub')}
                </AnchorLink>
              </li>

              <li>
                <AnchorLink href="https://twitter.com/getMoonMail" external target="_blank">
                  {t('MoonMail on Twitter')}
                </AnchorLink>
              </li>
            </ul>
          </SpamDescriptionContent>
        </SpamDescriptionContainer>
      </div>
    </>
  );
};

export default Page;

import styled, {keyframes} from 'styled-components';

const Rotating = keyframes`
    from {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

const Loader = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  vertical-align: middle;
  left: 0;
  top: 0;
  transform: none;
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em;
  text-align: center;
  z-index: 1000;
  &:after,
  &:before {
    width: 2.28571429rem;
    height: 2.28571429rem;
    margin: 0 0 0 -1.14285714rem;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: ${Rotating} 0.6s linear;
    animation: ${Rotating} 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
  }
`;

export default Loader;
